<template>
  <b-card
    class="product-card scale-up cursor-pointer"
    @click="openProductDetails"
  >
    <div
      class="
        d-flex
        flex-md-row flex-column
        align-items-center align-items-md-stretch
      "
    >
      <div
        class="
          col-md-3
          pl-0
          d-flex
          justify-content-center
          mb-md-0 mb-1
          align-items-center
          card-image-container
        "
      >
        <b-img-lazy
          v-bind="mainProps"
          :src="product.attributes.main_image"
          alt="Image"
          class="rounded product-card-image w-auto"
        />
      </div>
      <div class="col-md-7 d-flex flex-column pl-0 justify-content-between">
        <div>
          <div
            class="
              flex-column flex-lg-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
            "
          >
            <h4 class="font-weight-bolder text-dark text-md-left text-center w-100">
              {{ product.attributes.title }}
            </h4>
          </div>
          <div
            class="
              flex-column flex-lg-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
              mb-auto\
            "
          >
            <h5
              class="text-left w-100 ellipsis-2-line mb-1 description-no-style"
              v-html="product.attributes.body"
            />
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <h5
            v-for="tag in [
              { id: 1, name: 'MDSAP' },
              { id: 2, name: 'In-vitro-diagnostics' },
              { id: 3, name: 'In-vitro-diagnostics' },
            ]"
            :key="tag.id"
            class="mr-1 mt-10 mb-0"
          >
            <b-badge
              class="text-dark font-weight-normal"
              variant="secondary"
            >
              {{ tag.name }}
            </b-badge>
          </h5>
        </div>
      </div>
      <div class="col-md-2 d-flex px-0 flex-column justify-content-end align-items-md-end">
        <b-button
          v-if="is_own"
          variant="primary"
          class="price-button"
        >
          {{ $t("View") }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="price-button"
        >
          {{ product.attributes.price | priceFormat }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BImgLazy, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BImgLazy,
    BBadge,
  },
  filters: {
    priceFormat(price) {
      const formatPrice = price.split('.')[0]
      return `${formatPrice.replace(/(\d)(?=(\d{3})+(?!\d))/, '$1.')},-`
    },
  },
  props: {
    // eslint-disable-next-line
    product: {
      type: Object,
    },
    is_own: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    }
  },
  methods: {
    openProductDetails() {
      if (this.is_own) {
        this.$router.push(`/my_products/details/${this.product.id}`)
      } else this.$router.push(`/marketplace/details/${this.product.id}`)
    },
  },
}
</script>
